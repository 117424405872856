<template>
  <section>
    <title-section
      title="Sectores de productos"
    >
      <div class="level-item">
        <button class="button button_micro_dark" @click="activeModalAddSector = true">
          AGREGAR SECTOR
        </button>
      </div>
    </title-section>
    <sectors-main
      :activeModalAddSector="activeModalAddSector"
      @close-modal="activeModalAddSector = false"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Sector',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SectorsMain: () => import('@/components/Products/Sectors/SectorsMain.vue')
  },
  data () {
    return {
      activeModalAddSector: false
    }
  },
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>

<style lang="scss" scoped>

</style>
